import React, {useEffect, useState} from 'react';
import './App.css';
// @ts-ignore
import {readString} from 'react-papaparse';
// @ts-ignore
import codes from './codes.csv'

function App() {

  const [redirectUrls, setRedirectUrls] = useState<{[key: string]: string}>({});

  function getUrl(id: string){
    return redirectUrls[id] || redirectUrls.default
  }

  function redirect(){
    const urlSearchParams = new URLSearchParams(window.location.search);
    const qrId = urlSearchParams.get('qr');
    if(qrId){
      window.location.replace(getUrl(qrId));
    } else {
      window.location.replace(redirectUrls.default);
    }
  }

  useEffect(()=> {
    const papaConfig = {
          // @ts-ignore
      complete: (results, file) => {
        console.log('Parsing complete:', results, file);
        const urlMap: {[key: string]: string} = {}
        results.data.slice(1).forEach((row: string[]) => {
          const id = row[0]
          urlMap[id] = row[2]
        })
        setRedirectUrls({default: 'https://housesoflegends.com/', ...urlMap})
      },
      download: true,
    };
    // @ts-ignore
    readString(codes, papaConfig);

  }, [])

  useEffect(() => {
    if(redirectUrls.default){
      redirect()
    }
  }, [redirectUrls])
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Redirecting...
        </p>
      </header>
    </div>
  );
}

export default App;
